import type { UIRouter } from '@wix/tpa-router';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

export function configureRouter(router: UIRouter, params: ControllerParams) {
  const { isEditor, isPreview } = params.flowAPI.environment;

  if (isEditor || isPreview) {
    router.urlService.rules.initial({
      state: 'my-groups.groups',
    });
    router.urlService.rules.otherwise({
      state: 'my-groups.groups',
    });
  }
}
